@use '~@avita-co-jp/design-tokens/build/scss/variables';
@use '../../../styles/colors';
@use '../../../styles/typography';
@use '../../../styles/lengths';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: lengths.$secondaryButtonWidth;
  height: 100%;
  background: colors.$transparent;
  border: 1px solid variables.$white;
  border-radius: #{variables.$radius-32}px;

  @media only screen and (max-width: lengths.$tabletMinSize) {
    width: lengths.$secondaryButtonWidthSP;
  }

  span {
    display: inline-block;
    width: calc(100%);
    overflow: hidden;
    font: typography.$JPMediumFontSize16LineHeight24;
    color: variables.$white;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:disabled {
    // TODO: move to styles/opacities.scss
    span {
      opacity: 0.2;
    }
  }

  &:hover:enabled {
    border-color: colors.$gray100;

    span {
      color: colors.$gray100;
    }
  }
}
