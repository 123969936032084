@use 'sass:map';
@use 'sass:math';

// Introduction video on entrance page
$introductionVideoSize: (
  width: 352,
  height: 192,
);
$introductionVideoRatioForStyle: math.div(
  map.get(
    $map: $introductionVideoSize,
    $key: width,
  ),
  map.get(
    $map: $introductionVideoSize,
    $key: height,
  )
);
$introductionVideoRatioForMediaQuery: #{map.get(
    $map: $introductionVideoSize,
    $key: width,
  )} + '/' + #{map.get(
    $map: $introductionVideoSize,
    $key: height,
  )};
