@use '../../../styles/lengths';
@use '../../../styles/colors';
@use '../../../styles/typography';

.errorMessage {
  display: flex;
  gap: lengths.$dialogLoginBodyErrorMessageGap;

  svg {
    fill: colors.$red;
  }

  .errorLabel {
    font: typography.$JPSmallLineHeight24;
    color: colors.$red;
  }
}
