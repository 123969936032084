@use '../../../styles/colors';
@use '../../../styles/typography';
@use '../../../styles/lengths';

.container {
  display: flex;
  flex-direction: column;
  gap: lengths.$dialogLoginBodyGap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: lengths.$dialogLoginBodyPadding;
  background: colors.$dialogBackground;
}
