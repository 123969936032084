@use '~@avita-co-jp/design-tokens/build/scss/variables';
@use '../../../styles/colors';
@use '../../../styles/lengths';

.container {
  display: flex;
  gap: lengths.$dialogFooterGap;
  align-items: center;
  justify-content: flex-end;
  height: lengths.$dialogFooterHeight;
  padding: lengths.$dialogFooterPadding;
  background: colors.$dialogBackground;
  border-radius: 0 0 #{variables.$radius-8}px #{variables.$radius-8}px;

  @media only screen and (max-width: lengths.$tabletMinSize) {
    height: lengths.$dialogFooterSPHeight;
  }
}
