@use '../../../styles/lengths';
@use '../../../styles/shadows';

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .dialogLogin {
    display: flex;
    flex-direction: column;
    width: lengths.$dialogWidth;
    min-width: 270px;
    filter: drop-shadow(shadows.$dialogShadow);

    @media only screen and (max-width: lengths.$dialogWidth) {
      width: 90%;
    }
  }
}
