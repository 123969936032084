@use '~@avita-co-jp/design-tokens/build/scss/variables';
@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/lengths';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: lengths.$smallButtonIconOnlySize;
  height: lengths.$smallButtonIconOnlySize;
  background: colors.$smallButtonDefault;
  border-width: 0;
  border-radius: radius.$circle;

  @media only screen and (max-width: lengths.$tabletMinSize) {
    width: lengths.$smallButtonIconOnlySizeSP;
    height: lengths.$smallButtonIconOnlySizeSP;
  }

  &:disabled {
    cursor: default;

    svg {
      // TODO: move to styles/opacities.scss
      opacity: 0.2;
    }
  }

  &:hover:enabled {
    background: colors.$smallButtonHover;
  }

  .wrapSvg {
    display: grid;
    align-items: center;

    svg {
      width: lengths.$smallButtonIconOnlyIconSize;
      height: lengths.$smallButtonIconOnlyIconSize;
      fill: variables.$white;
    }
  }
}
