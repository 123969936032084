@use '~@avita-co-jp/design-tokens/build/scss/variables';
@use '../../../styles/colors';
@use '../../../styles/typography';
@use '../../../styles/lengths';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: lengths.$dialogHeaderHeight;
  background: colors.$dialogBackground;
  border-bottom: 1px solid variables.$white;
  border-radius: #{variables.$radius-8}px #{variables.$radius-8}px 0 0;

  &[data-is-text-left-aligned='true'] {
    justify-content: flex-start;
    padding: 0 16px;
  }

  .buttonClose {
    position: absolute;
    top: 10px;
    right: 15px;
  }

  span {
    font: typography.$JPLargeFontSize19LineHeight27;
    color: variables.$white;
  }
}
