@use '~@avita-co-jp/design-tokens/build/scss/variables';
@use './globalTokens' as gt;

// FontFamily
$fontFamily-noto-sans-jp: variables.$font-families-noto-sans-jp, sans-serif;
$fontFamily-barlow: variables.$font-families-barlow, sans-serif;

// TODO: $JPExtraLargeLineHeight36 & $JPSmallLineHeight24 needs to be renamed
$JPSmallLineHeight18: gt.$fontStyleNormal gt.$fontWeight400 #{variables.$font-size-1}px / #{gt.$lineHeight18} gt.$fontFamily;
$JPSmallLineHeight24: gt.$fontStyleNormal gt.$fontWeight400 #{variables.$font-size-1}px / #{gt.$lineHeight24} gt.$fontFamily;
$JPMediumLineHeight14: gt.$fontStyleNormal gt.$fontWeight400 #{variables.$font-size-2}px / #{gt.$lineHeight14} gt.$fontFamily;
$JPMediumLineHeight21: gt.$fontStyleNormal gt.$fontWeight400 #{variables.$font-size-2}px / #{gt.$lineHeight21} gt.$fontFamily;
$JPMediumLineHeight21Bold: gt.$fontStyleNormal gt.$fontWeight700 #{variables.$font-size-2}px / #{gt.$lineHeight21} gt.$fontFamily;
$JPMediumLineHeight24: gt.$fontStyleNormal gt.$fontWeight400 #{variables.$font-size-2}px / #{gt.$lineHeight24} gt.$fontFamily;
$JPMediumFontSize16: gt.$fontStyleNormal gt.$fontWeight500 #{variables.$font-size-3}px / #{gt.$lineHeight16} gt.$fontFamily;
$JPMediumFontSize16LineHeight18: gt.$fontStyleNormal gt.$fontWeight700 #{variables.$font-size-3}px / #{gt.$lineHeight18}
  gt.$fontFamily;
$JPMediumFontSize16LineHeight24: gt.$fontStyleNormal gt.$fontWeight500 #{variables.$font-size-3}px / #{gt.$lineHeight24}
  gt.$fontFamily;
$JPLargeFontSize19LineHeight27: gt.$fontStyleNormal gt.$fontWeight700 #{variables.$font-size-4}px / #{gt.$lineHeight27} gt.$fontFamily;
$JPLargeFontSize19LineHeight19: gt.$fontStyleNormal gt.$fontWeight700 #{variables.$font-size-4}px / #{gt.$lineHeight19} gt.$fontFamily;
$JPExtraLargeLineHeight24: gt.$fontStyleNormal gt.$fontWeight700 #{variables.$font-size-5}px / #{gt.$lineHeight24} gt.$fontFamily;
$JPExtraLargeLineHeight28: gt.$fontStyleNormal gt.$fontWeight700 #{gt.$fontSize28} / #{gt.$lineHeight28} gt.$fontFamily;
$JPExtraLargeLineHeight36: gt.$fontStyleNormal gt.$fontWeight700 #{variables.$font-size-5}px / #{gt.$lineHeight36} gt.$fontFamily;

// ENG styles
$ENGPageError: gt.$fontStyleNormal gt.$fontWeight700 #{gt.$fontSize120} / #{gt.$lineHeight120} gt.$fontFamily;
$ENGSmall: gt.$fontStyleNormal gt.$fontWeight400 #{variables.$font-size-1}px / #{gt.$lineHeight24} gt.$fontFamily;

// TODO: design-tokensを修正後に$ENGLabelExtraSmallのfont-sizeを置き換え

/* JP/Heading */
$JPHeadingExtraExtraLarge: gt.$fontStyleNormal variables.$jp-heading-xxl-font-weight #{variables.$jp-heading-xxl-font-size}px /
    variables.$jp-heading-xxl-line-height variables.$jp-heading-xxl-font-family,
  sans-serif;
$JPHeadingExtraLarge: gt.$fontStyleNormal variables.$jp-heading-xl-font-weight #{variables.$jp-heading-xl-font-size}px /
    variables.$jp-heading-xl-line-height variables.$jp-heading-xl-font-family,
  sans-serif;
$JPHeadingLarge: gt.$fontStyleNormal variables.$jp-heading-l-font-weight #{variables.$jp-heading-l-font-size}px /
    variables.$jp-heading-l-line-height variables.$jp-heading-l-font-family,
  sans-serif;
$JPHeadingMedium: gt.$fontStyleNormal variables.$jp-heading-m-font-weight #{variables.$jp-heading-m-font-size}px /
    variables.$jp-heading-m-line-height variables.$jp-heading-m-font-family,
  sans-serif;
$JPHeadingSmall: gt.$fontStyleNormal variables.$jp-heading-s-font-weight #{variables.$jp-heading-s-font-size}px /
    variables.$jp-heading-s-line-height variables.$jp-heading-s-font-family,
  sans-serif;

/* JP/Body */
$JPBodyMedium: gt.$fontStyleNormal variables.$jp-body-m-font-weight #{variables.$jp-body-m-font-size}px /
    variables.$jp-body-m-line-height variables.$jp-body-m-font-family,
  sans-serif;
$JPBodySmall: gt.$fontStyleNormal variables.$jp-body-s-font-weight #{variables.$jp-body-s-font-size}px /
    variables.$jp-body-s-line-height variables.$jp-body-s-font-family,
  sans-serif;

/* JP/Label */
// Normal
$JPLabelMediumNormal: gt.$fontStyleNormal variables.$jp-label-m-normal-font-weight #{variables.$jp-label-m-normal-font-size}px /
    variables.$jp-label-m-normal-line-height variables.$jp-label-m-normal-font-family,
  sans-serif;
$JPLabelSmallNormal: gt.$fontStyleNormal variables.$jp-label-s-normal-font-weight #{variables.$jp-label-s-normal-font-size}px /
    variables.$jp-label-s-normal-line-height variables.$jp-label-s-normal-font-family,
  sans-serif;
$JPLabelExtraSmallNormal: gt.$fontStyleNormal variables.$jp-label-xs-normal-font-weight #{variables.$jp-label-xs-normal-font-size}px /
    variables.$jp-label-xs-normal-line-height variables.$jp-label-xs-normal-font-family,
  sans-serif;

// Bold
$JPLabelMediumBold: gt.$fontStyleNormal variables.$jp-label-m-bold-font-weight #{variables.$jp-label-m-bold-font-size}px /
    variables.$jp-label-m-bold-line-height variables.$jp-label-m-bold-font-family,
  sans-serif;
$JPLabelSmallBold: gt.$fontStyleNormal variables.$jp-label-s-bold-font-weight #{variables.$jp-label-s-bold-font-size}px /
    variables.$jp-label-s-bold-line-height variables.$jp-label-s-bold-font-family,
  sans-serif;
$JPLabelExtraSmallBold: gt.$fontStyleNormal variables.$jp-label-xs-bold-font-weight #{variables.$jp-label-xs-bold-font-size}px /
    variables.$jp-label-xs-bold-line-height variables.$jp-label-xs-bold-font-family,
  sans-serif;

/* ENG/Heading */
$ENGHeadingExtraExtraLarge: gt.$fontStyleNormal variables.$eng-heading-xxl-font-weight #{variables.$eng-heading-xxl-font-size}px /
    variables.$eng-heading-xxl-line-height variables.$eng-heading-xxl-font-family,
  sans-serif;
$ENGHeadingExtraLarge: gt.$fontStyleNormal variables.$eng-heading-xl-font-weight #{variables.$eng-heading-xl-font-size}px /
    variables.$eng-heading-xl-line-height variables.$eng-heading-xl-font-family,
  sans-serif;
$ENGHeadingLarge: gt.$fontStyleNormal variables.$eng-heading-l-font-weight #{variables.$eng-heading-l-font-size}px /
    variables.$eng-heading-l-line-height variables.$eng-heading-l-font-family,
  sans-serif;
$ENGHeadingMedium: gt.$fontStyleNormal variables.$eng-heading-m-font-weight #{variables.$eng-heading-m-font-size}px /
    variables.$eng-heading-m-line-height variables.$eng-heading-m-font-family,
  sans-serif;
$ENGHeadingSmall: gt.$fontStyleNormal variables.$eng-heading-s-font-weight #{variables.$eng-heading-s-font-size}px /
    variables.$eng-heading-s-line-height variables.$eng-heading-s-font-family,
  sans-serif;

/* ENG/Body */
$ENGBodyMedium: gt.$fontStyleNormal variables.$eng-body-m-font-weight #{variables.$eng-body-m-font-size}px /
    variables.$eng-body-m-line-height variables.$eng-body-m-font-family,
  sans-serif;
$ENGBodySmall: gt.$fontStyleNormal variables.$eng-body-s-font-weight #{variables.$eng-body-s-font-size}px /
    variables.$eng-body-s-line-height variables.$eng-body-s-font-family,
  sans-serif;

/* ENG/Label */
// Normal
$ENGLabelMediumNormal: gt.$fontStyleNormal variables.$eng-label-m-normal-font-weight #{variables.$eng-label-m-normal-font-size}px /
    variables.$eng-label-m-normal-line-height variables.$eng-label-m-normal-font-family,
  sans-serif;
$ENGLabelSmallNormal: gt.$fontStyleNormal variables.$eng-label-s-normal-font-weight #{variables.$eng-label-s-normal-font-size}px /
    variables.$eng-label-s-normal-line-height variables.$eng-label-s-normal-font-family,
  sans-serif;
$ENGLabelExtraSmallNormal: gt.$fontStyleNormal variables.$eng-label-xs-normal-font-weight #{10}px /
    variables.$eng-label-xs-normal-line-height variables.$eng-label-xs-normal-font-family,
  sans-serif;

// Bold
$ENGLabelMediumBold: gt.$fontStyleNormal variables.$eng-label-m-bold-font-weight #{variables.$eng-label-m-bold-font-size}px /
    variables.$eng-label-m-bold-line-height variables.$eng-label-m-bold-font-family,
  sans-serif;
$ENGLabelSmallBold: gt.$fontStyleNormal variables.$eng-label-s-bold-font-weight #{variables.$eng-label-s-bold-font-size}px /
    variables.$eng-label-s-bold-line-height variables.$eng-label-s-bold-font-family,
  sans-serif;
$ENGLabelExtraSmallBold: gt.$fontStyleNormal variables.$eng-label-xs-bold-font-weight #{10}px /
    variables.$eng-label-xs-bold-line-height variables.$eng-label-xs-bold-font-family,
  sans-serif;
