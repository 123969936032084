@use '~@avita-co-jp/design-tokens/build/scss/variables';
@use '../../../styles/lengths';
@use '../../../styles/colors';
@use '../../../styles/radius';
@use '../../../styles/shadows';
@use '../../../styles/typography';

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: lengths.$textFieldWidth;
  height: lengths.$textFieldHeight;

  .textField {
    width: 100%;
    height: 100%;
    padding: 8px 16px;
    color: colors.$deepBlack;
    background: variables.$white;
    border: lengths.$borderWidth2 solid colors.$transparent;
    border-radius: radius.$textFieldRadius;
    box-shadow: shadows.$textFieldShadowDefault;

    &::placeholder {
      font: typography.$JPMediumLineHeight24;
      color: colors.$gray;
      letter-spacing: normal;
    }

    &.error {
      border-color: variables.$red-100;
    }

    &.dotCharacter {
      padding-right: 48px;
      letter-spacing: 10px;

      &::-ms-reveal {
        display: none;
      }
    }

    &:focus {
      border-color: variables.$royal-blue-100;
      box-shadow: shadows.$textFieldFocusShadow;
    }

    &:disabled {
      background: colors.$gray;

      &::placeholder {
        color: colors.$deepBlack20;
      }
    }
  }

  .iconEye {
    position: absolute;
    top: 8px;
    right: 16px;

    svg {
      width: lengths.$textFieldIconSize;
      height: lengths.$textFieldIconSize;
      fill: colors.$deepBlack;
    }
  }
}

@media only screen and (max-width: lengths.$dialogWidth) {
  .container {
    width: 100%;
  }
}
