@use '~@avita-co-jp/design-tokens/build/scss/variables';
@use '../../../styles/lengths';
@use '../../../styles/colors';
@use '../../../styles/ratios';

.dynamicBackground {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .videoContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .videoIframe {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: 110%;
      aspect-ratio: ratios.$introductionVideoRatioForStyle;
      border: 0;
      transform: translate(-50%, -50%);

      @media screen and (min-aspect-ratio: ratios.$introductionVideoRatioForMediaQuery) {
        width: 110%;
        height: auto;
      }
    }
  }

  .backgroundImage {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: colors.$backgroundOpacity;
  }
}

@media only screen and (max-width: lengths.$spWidth) {
  .dynamicBackground {
    background-image: url('../../../assets/images/background.jpg');
    background-position: center top;
    background-size: cover;
  }

  .videoContainer {
    display: none;
  }
}
