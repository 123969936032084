@use '~@avita-co-jp/design-tokens/build/scss/variables';
@use '../../../styles/lengths';
@use '../../../styles/typography';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .iconAndLabel {
    display: flex;
    gap: lengths.$inputLabelWithTextFormLabelGap;
    align-items: center;

    svg {
      width: lengths.$inputLabelWithTextFormIconSize;
      height: lengths.$inputLabelWithTextFormIconSize;
      fill: variables.$white;
    }

    span {
      font: typography.$JPMediumFontSize16LineHeight24;
      color: variables.$white;
    }
  }
}

@media only screen and (max-width: lengths.$dialogWidth) {
  .container {
    flex-direction: column;
    gap: lengths.$inputLabelWithTextFormLabelAndInputGap;
    align-items: flex-start;
  }
}
